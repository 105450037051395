import HousekeepingLinks from '../components/housekeeping-links'

export default function AuthLayout({ children }) {
    return (
        <div className='flex flex-col h-screen overflow-auto bg-slate-50'>
            <div className='flex flex-col justify-center flex-1 pb-20'>
                <a href="https://theboomerangtag.com/" target="_blank" className='sm:mx-auto sm:w-full sm:max-w-md shrink-0'>
                    <img className='w-64 h-auto mx-auto mb-2' src='/logo.svg' alt='' />
                </a>
                <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
                    <div className='px-4 py-8 bg-white rounded shadow sm:px-10'>{children}</div>
                </div>
            </div>
            <HousekeepingLinks />
        </div>
    )
}
