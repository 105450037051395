import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { api, apiNoAuth } from '../services/api.service';

import AuxiliaryOwnershipDetails from '../components/auxiliary-product-details';
import Card from '../components/card';
import ImageViewer from '../components/image-viewer';
import Loading from '../components/loading';

import FindProductForm from '../forms/find-product-form';
import FormModal from '../forms/form-modal';
import LostProductForm from '../forms/lost-product-form';

import RehomeConfirm from '../components/rehome-confirm';
import { style } from '../style';
import LostModal from '../components/lostModal';
import S3FileViewer from '../components/S3Viewer';

// Configuration texts
const messages = {
    helpReturn: "Thanks for helping return this item with Boomerang.",
    lost: "Uh oh! You've lost it.",
    helpOnTheWay: "Don't worry too much though, help is just around the corner. We feel it!",
    contactDetails: "Just provide some contact details such as your phone number and name for the finder here.",
    anonymous: "If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.",
    fingersCrossed: "Our fingers and toes are crossed for you!",
    junkMail: "Make sure Boomerang is an authorised sender, we wouldn't want it to end up in your junk mail.",
    hero: "Thanks for being a hero."
};

export default function OwnershipDetails(props) {
    const [data, setData] = useState(null);
    const [userType, setUserType] = useState(null);
    const [user, setUser] = useState(null);
    const [showFindModal, setShowFindModal] = useState(false);
    const [showLostModal, setShowLostModal] = useState(false);
    const [showLostModalForFoundItem, setShowLostModalForFoundItem] = useState(false);
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const params = useParams();
    const [confirmDisown, setConfirmDisown] = useState(false);
    const [recentOwnership, setRecentOwnership] = useState(false);

    const id = params.id ?? props.id;

    useEffect(() => {
        apiNoAuth(`${process.env.REACT_APP_API_URL}/app/check-user-type`).then((x) => setUserType(x.userType));
    }, []);

    useEffect(() => {
        if (userType === 'merchant' || userType === 'user') {
            api(`${process.env.REACT_APP_API_URL}/app/get-current-user-details`).then((x) => setUser(x.user))
            api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => api(`${process.env.REACT_APP_API_URL}/app/foundEvents/${id}`).then((y) => setData({ ...x, foundEvent: y.foundEvent, foundEvents: y.allFoundEvents })))
        } else api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x))
    }, [userType, id]);

    const fetchData = useCallback(() => {
        api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => setData(x));
    }, [id]);

    const markAsReturned = () => {
        api(`${process.env.REACT_APP_API_URL}/app/mark-as-returned/${id}`).then(fetchData);
    };

    const claimItem = () => {
        api(`${process.env.REACT_APP_API_URL}/app/claim-product/${id}`).then(() => {
            setRecentOwnership(true);
            fetchData();
        });
    };

    const disown = (id) => {
        api(`${process.env.REACT_APP_API_URL}/app/disown-product/${id}`).then(fetchData);
    };

    const refresh = () => [
        api(`${process.env.REACT_APP_API_URL}/public/ownership/${id}`).then((x) => api(`${process.env.REACT_APP_API_URL}/app/foundEvents/${id}`).then((y) => setData({ ...x, foundEvent: y.foundEvent, foundEvents: y.allFoundEvents })))
    ]

    const getProductName = (item) => {
        if (!item.product && !item.name) {
            return 'Custom Item';
        } else if (!item.product) {
            return item.name;
        } else {
            return item.product?.name;
        }
    };

    const HandleButtonFlow = () => {
        const navigate = useNavigate();
        const { ownership } = data;
        const isCurrentUserOwner = ownership.currentOwner?._id === user?._id;
        if (ownership.currentOwner && !isCurrentUserOwner && !ownership.isLost) return <button className={twMerge(style.button.classname, style.button._secondary.classname) + ' w-full text-xl'} onClick={() => setShowFindModal(true)}>Found Me?</button>
        if (user) {
            if (ownership.currentOwner) {
                if (isCurrentUserOwner) {
                    return (
                        <>
                            <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={(ownership.isLost || ownership.isFound) ? () => markAsReturned() : () => setShowLostModal(true)}>{(ownership.isLost || ownership.isFound) ? 'Returned' : 'Lost'}</button>
                            <button className={twMerge(style.button.classname, style.button._dark.classname)} onClick={() => { setConfirmDisown(data.ownership._id) }}>Rehome</button>
                            <button className={twMerge(style.button.classname, 'bg-light')} onClick={() => {
                                navigate(`/create-ownership/${data.ownership._id}`)
                            }}>
                                Manage
                            </button>
                        </>
                    );
                }
            } else {
                return <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={ownership.isCustom ? () => navigate(`/create-ownership/${id}?new=true`) : () => claimItem()}>Claim Item</button>
            }
        }
        else {
            if (data.ownership.currentOwner) {
                if (!data.ownership.isLost) {
                    return (
                        <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowFindModal(true)}>
                            Found Me?
                        </button>
                    )
                }
            } else {
                return (
                    <>
                        <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => navigate('/register?rd=%2Fownership%2F' + id)}>
                            Register And Tag
                        </button>
                        <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => navigate('/login?rd=%2Fownership%2F' + id)}>
                            Login And Tag
                        </button>
                    </>
                )
            }
        }
    };

    if (!data || !data.ownership) return <Loading />;

    const PersonItemDisplay = ({ property, value }) => {
        return <div className='flex flex-row space-x-2' onClick={() => { navigator.clipboard.writeText(value); toast("Copied to clipboard!") }}>
            <div className='font-bold'>{property}: </div>
            <div className='text-brand flex-1 truncate'>{value}</div>
        </div>
    }

    const shouldShowPropertyWhenLost = (permissions, property) => {
        return permissions?.[property]?.showWhenLost || permissions?.[property]?.showAlways;
    };

    const shouldShowPropertyAlways = (permissions, property) => {
        return permissions?.[property]?.showAlways;
    };

    const getDisplayValue = (temporaryOverrideValue, overrideValue, defaultValue) => {
        return temporaryOverrideValue || (overrideValue || defaultValue);
    };

    const userDetailDisplay = () => {
        const personalInfo = [];
        const properties = [{ field: 'name', label: 'First Name' }, { field: 'lastName', label: 'Last Name' }, { field: 'email', label: 'Email' }, { field: 'phoneNumber', label: 'Phone Number' }];

        properties.forEach(property => {
            const overrideProperty = `override${property.field.charAt(0).toUpperCase() + property.field.slice(1)}`;
            const temporaryOverrideProperty = `temporaryOverride${property.field.charAt(0).toUpperCase() + property.field.slice(1)}`;

            if (data.ownership.isLost) {
                if (data.ownership.temporaryOverridePermissions && data.ownership.temporaryOverridePermissions[property.field] !== undefined) {
                    if (shouldShowPropertyWhenLost(data.ownership.temporaryOverridePermissions, property.field)) {
                        personalInfo.push(<PersonItemDisplay property={property.label} value={getDisplayValue(data.ownership[temporaryOverrideProperty], data.ownership[overrideProperty], data.ownership.currentOwner[property.field])} />);
                    }
                }
                else if (data.ownership.overridePermissions && data.ownership.overridePermissions[property.field] !== undefined) {
                    if (shouldShowPropertyWhenLost(data.ownership.overridePermissions, property)) {
                        personalInfo.push(<PersonItemDisplay property={property.label} value={getDisplayValue(data.ownership[temporaryOverrideProperty], data.ownership[overrideProperty], data.ownership.currentOwner[property.field])} />);
                    }
                } else if (shouldShowPropertyWhenLost(data.ownership.currentOwner?.defaultPermissions, property.field)) {
                    personalInfo.push(<PersonItemDisplay property={property.label} value={getDisplayValue(data.ownership[temporaryOverrideProperty], data.ownership[overrideProperty], data.ownership.currentOwner[property.field])} />);
                }
            } else { 
                if (data.ownership.overridePermissions && data.ownership.overridePermissions[property.field] !== undefined) {
                    if (shouldShowPropertyAlways(data.ownership.overridePermissions, property.field)) {
                        personalInfo.push(<PersonItemDisplay property={property.label} value={getDisplayValue(data.ownership[temporaryOverrideProperty], data.ownership[overrideProperty], data.ownership.currentOwner[property.field])} />);
                    }
                } else if (shouldShowPropertyAlways(data.ownership.currentOwner?.defaultPermissions, property.field)) {
                    personalInfo.push(<PersonItemDisplay property={property.label} value={getDisplayValue(data.ownership[temporaryOverrideProperty], data.ownership[overrideProperty], data.ownership.currentOwner[property.field])} />);
                }
            }
        });
        return <div>{personalInfo.map(x => x)}</div>;
    };

    return (
        <div>
            <RehomeConfirm
                open={!!confirmDisown}
                confirm={() => {
                    disown(confirmDisown);
                    setConfirmDisown(false);
                }}
                cancel={() => {
                    setConfirmDisown(false);
                }}
            />

            <FormModal open={showFindModal} setOpen={setShowFindModal}>
                <div className='w-full max-w-xl p-5 bg-white rounded-md shadow'>
                    <div className='w-full text-center'>{messages.helpReturn}</div>
                    <FindProductForm
                        submitted={() => {
                            setSubmitted(true);
                            setShowFindModal(false);
                        }}
                        id={id}
                    />
                </div>
            </FormModal>

            <FormModal open={showLostModalForFoundItem} setOpen={setShowLostModalForFoundItem}>
                <div className='w-full max-w-xl p-5 text-sm bg-white rounded-md shadow sm:text-base'>
                    <div className='mb-2 text-neutral-800'>Hooray! Someone has found your item.</div>
                    <div className='mb-2 text-neutral-800'>To get your much loved belonging back as fast as possible, we suggest leaving your contact number and name.</div>
                    <div className='mb-2 text-neutral-800'>If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.</div>
                    <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                    <div className='mb-2 text-neutral-800'>p.s. be sure to check your junk mail we wouldn't want it to end up in there!</div>
                    <LostProductForm
                        submitted={() => {
                            refresh()
                            setShowLostModalForFoundItem(false)
                        }}
                        id={id}
                    />
                </div>
            </FormModal>

            {user && data.ownership.currentOwner?._id === user?._id && <LostModal id={showLostModal ? id : ''} setId={setShowLostModal} refresh={refresh} />}

            <Card className='p-5 mt-10'>
                <ToastContainer autoClose={2000} position='bottom-right' />

                <div className='flex flex-col justify-evenly sm:space-x-8 sm:flex-row'>
                    <div className='shrink-0'>
                        <div className='text-sm text-neutral-600'> {data.ownership?.product?.brand || ''}</div>
                        <div className='mb-5 text-3xl font-black'>{getProductName(data.ownership)}</div>
                        {(data.ownership.s3Image || data.ownership.product?.s3Image) && <S3FileViewer className='object-cover w-64 h-64 rounded-md shadow' fileKey={data.ownership.product?.s3Image || data.ownership.s3Image} />}
                        {!data.ownership.isCustom && (data.ownership.size || data.ownership.category || data.ownership.colour || data.ownership.style || data.ownership.additionalDetails) && <AuxiliaryOwnershipDetails product={data.ownership.product} />}

                        <div className='flex flex-col items-start mt-10 space-y-3'>{<HandleButtonFlow />}</div>
                    </div>

                    <div className='mt-5 text-left text-neutral-700'>
                        {!data.ownership.isLost && <div className='mb-5'>
                            <div className='text-xl'>{userDetailDisplay()}</div>
                        </div>}

                        {!data.ownership.currentOwner?._id && (
                            <div>
                                <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                <div className='mt-1 mb-3 text-lg'>Nametags just got smart.</div>
                                <div className='mt-1 mb-3 text-lg'>Say hi to BoomerangTag.</div>
                                <div className='mt-1 mb-3 text-lg'> Never lose this item again. {userType === 'anonymous' && 'Simply register and claim it!'}</div>
                            </div>
                        )}
                        {data.ownership.currentOwner?._id && data.ownership.currentOwner?._id !== user?._id && !data.ownership.isLost && (
                            <div>
                                <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                <div className='mt-1 mb-3 text-lg'>Nametags just got smart.</div>
                                <div className='mt-1 mb-3 text-lg'>We're on a mission to save 100,000 items to reduce the giant pile of global lost property and help people reunite with their most loved belongings.</div>
                                <div className='mt-1 mb-3 text-lg'>Have you found this item? Amazing!</div>
                                <div className='mt-1 mb-3 text-lg'>Tap "Found me?” and leave contact details so we can send details of how to return the item to its owner who will be very relieved!</div>
                            </div>
                        )}
                        {data.ownership.currentOwner?._id !== user?._id && data.ownership.isLost && (
                            <div>
                                <div className='text-xl font-medium text-neutral-800'>Welcome to Boomerang!</div>
                                <div className='mt-1 mb-3 text-lg'>Thanks for finding this item. You are a HERO!</div>
                                <div className='mt-1 mb-3 text-lg'>The owner has left these details to return their item:</div>
                                <div className='mt-1 mb-3 text-lg font-semibold text-brand'>{data.ownership.returnInstructions || data.ownership.currentOwner?.defaultReturnInstructions}</div>
                                {data.ownership.isLost && <div className='mb-5'>
                                    <div className='text-xl'>{userDetailDisplay()}</div>
                                </div>}
                                <div className='mt-1 mb-3 text-lg'>You can also tap contact owner to get in touch with them. They will be notified anonymously by email with your note with return details to them.</div>
                                <div className='mt-1 mb-3 text-lg'>{messages.hero}</div>
                                <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowFindModal(true)}>
                                    Contact Owner
                                </button>
                            </div>
                        )}
                        {!data.ownership.isLost && !data.ownership.isFound && data.ownership.currentOwner?._id && data.ownership.currentOwner?._id === user?._id && (
                            <div>
                                <div className='text-xl font-medium text-neutral-800'>Hooray! You own this item</div>
                                <div className='mt-1 mb-3 text-lg'>We hope you will love this item like one of your own.</div>
                                <div className='mt-1 mb-3 text-lg'>
                                    If you lose it - and we really hope you don't - mark "Lost" to help the finder return the item. If the finder finds it first they will leave a note for you and an email to notify you.
                                </div>
                                <div className='mt-1 mb-3 text-lg'>
                                    If you want to give the item to someone else, tap "Rehome" so the new owner can claim it.
                                </div>
                                <div className='mt-1 mb-3 text-lg'>
                                    Tap “Manage” to adjust what is shown on this one item at any time or when marked lost. You can still adjust this at the time of losing if needed.
                                </div>
                            </div>
                        )}
                        {submitted && <div className='text-center text-neutral-700'>{messages.helpReturn}</div>}
                        {data.ownership.isFound && user && data.ownership.currentOwner?._id === user?._id && (
                            <>
                                <div className='text-xl font-medium'>Hooray! Your item has been found.</div>
                                <div className='mt-1 text-neutral-700'>Here's a message from the hero who found it:</div>
                                {data.foundEvents?.length > 1 ? (
                                    data.foundEvents.map((x, index) => (
                                        <div key={index} className='mt-3 font-medium text-neutral-800'>
                                            {x?.note}
                                        </div>
                                    ))
                                ) : (
                                    <div className='mt-3 font-medium text-neutral-800'>{data.foundEvent?.note}</div>
                                )}
                                <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowLostModalForFoundItem(true)}>Message Finder</button>

                            </>
                        )}
                        {data.ownership.currentOwner?._id === user?._id && data.ownership.isLost && !data.ownership.isFound && (
                            <>
                                <div className='mt-1 mb-3 text-lg'>Oh no! This item is lost. But fear not, Boomerang help is on the way.</div>
                                <div className='mt-1 mb-3 text-lg'>If someone finds it, they will contact you with the details you left to return the item and we'll email you as well.</div>
                                <div className='mt-1 mb-3 text-lg'>{messages.junkMail}</div>
                                <div className='mt-1 mb-3 text-lg'>{messages.fingersCrossed}</div>
                                {(data.ownership.returnInstructions || data.ownership?.currentOwner?.defaultReturnInstructions) && (
                                    <>
                                        <div className='mt-10 text-xs text-neutral-400'>Details you have left for the finder:</div>
                                        <div className='my-2 text-lg font-medium'>{(data.ownership.returnInstructions || data.ownership?.currentOwner?.defaultReturnInstructions)}</div>
                                    </>
                                )}
                                {!(data.ownership.returnInstructions || data.ownership?.currentOwner?.defaultReturnInstructions) && (
                                    <div className='my-2 text-lg font-medium'>You have not left any details for the finder</div>
                                )}
                            </>
                        )}
                    </div>
                </div>


            </Card>



            <ToastContainer autoClose={2000} position='bottom-right' />

        </div>
    );
}


