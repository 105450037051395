import { useEffect, useState } from "react";
import { SliderField, TextAreaField, TextField } from "../forms/fields";
import FormModal from "../forms/form-modal";
import { style } from '../style'
import { twMerge } from "tailwind-merge";
import { api } from "../services/api.service";

export default function LostModal({ id, setId, refresh }) {

    const [ownership, setOwnership] = useState()
    const [userData, setUserData] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/ownership/${id}`).then((x) => {
            setOwnership(x)
        })
        api(`${process.env.REACT_APP_API_URL}/app/profile`).then((x) => {
            setUserData(x)
        })
    }, [id])

    const submitMarkAsLost = () => {
        api(`${process.env.REACT_APP_API_URL}/app/mark-as-lost/${id}`, { ...ownership }).then((x) => {
            refresh()
            setId(null)
        })
    }

    const handleCheckboxChange = (field, subField, value, setValues) => {
        setValues(prev => ({
            ...prev,
            temporaryOverridePermissions: {
                ...prev.temporaryOverridePermissions,
                [field]: {
                    ...prev.temporaryOverridePermissions?.[field],
                    [subField]: value
                }
            }
        }))
    }

    return (
        <FormModal open={!!id} setOpen={() => setId(null)}>
            <div className='w-full max-w-6xl p-5 text-sm bg-white rounded-md shadow sm:text-base max-h-[80dvh] overflow-auto'>
                <div className='mb-2 text-neutral-800'>Uh oh! You've lost it.</div>
                <div className='mb-2 text-neutral-800'>Don't worry too much though, help is just around the corner. We feel it!</div>
                <div className='mb-2 text-neutral-800'>Just provide some contact details such as your phone number and name for the finder here.</div>
                <div className='mb-2 text-neutral-800'>If you don't leave any details, that's also OK, the finder will still be able to send an anonymous email to you with return details.</div>
                <div className='mb-2 text-neutral-800'>Our fingers and toes are crossed for you!</div>
                <div className='mb-2 text-neutral-800'>p.s. make sure Boomerang is an authorised sender, we wouldn't want it to end up in your junk mail.</div>
                <TextAreaField rows={2} value={ownership?.returnInstructions || userData?.defaultReturnInstructions} onChange={(val) => setOwnership(x => ({ ...x, returnInstructions: val }))} label='Return Instructions' />

                <button className={twMerge(style.button.classname, style.button._secondary.classname, 'mt-2')} onClick={submitMarkAsLost}>
                    Submit
                </button>
                <div className="mt-5 text-left">
                    <SettingsOverride userData={userData} handleCheckboxChange={handleCheckboxChange} values={ownership} setValues={setOwnership} />
                </div>
            </div>
        </FormModal>
    )
}

const SettingsOverride = ({ values, setValues, userData, handleCheckboxChange }) => {
    if (!values) return null;

    const fields = [
        { label: "First Name", field: "name", temporaryField: "temporaryOverrideName", overrideField: "overrideName" },
        { label: "Last Name", field: "lastName", temporaryField: "temporaryOverrideLastName", overrideField: "overrideLastName" },
        { label: "Email", field: "email" },
        { label: "Phone Number", field: "phoneNumber" }
    ];

    return (
        <div className='flex flex-col space-y-10 divide-y' key={JSON.stringify(values?.temporaryOverridePermissions)}>
            {fields.map(({ label, field, temporaryField, overrideField }) => {
                const isMutable = !!temporaryField;
                const value = values[temporaryField] || values[overrideField] || userData?.[field]; 
                const permissionValue = values.temporaryOverridePermissions?.[field]?.showWhenLost !== undefined
                    ? values.temporaryOverridePermissions?.[field]?.showWhenLost
                    : values.overridePermissions?.[field]?.showWhenLost !== undefined
                        ? (values.overridePermissions?.[field]?.showWhenLost || values.overridePermissions?.[field]?.showAlways)
                        : (userData.defaultPermissions?.[field]?.showWhenLost || userData.defaultPermissions?.[field]?.showAlways);

                return (
                    <div className='flex space-x-5' key={field}>
                        <div className='flex-1'>
                            {isMutable ? (
                                <TextField 
                                    label={label} 
                                    value={value} 
                                    onChange={(val) => setValues(prev => ({
                                        ...prev,
                                        [temporaryField]: val
                                    }))} 
                                />
                            ) : (
                                <div>
                                    <div>{label}</div>
                                    <div>{value}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <SliderField
                                label="Show?"
                                value={permissionValue}
                                onChange={(val) => handleCheckboxChange(field, 'showWhenLost', val, setValues)}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}