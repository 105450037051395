import { Card } from '@tremor/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import ImageViewer from '../components/image-viewer'
import Loading from '../components/loading'

import { style } from '../style'
import { CurrencyField, TextAreaField } from './fields'
import FormModal from './form-modal'
import FormWrapper from './form-wrapper'

export default function EditListingForm(props) {
    const [data, setData] = useState()
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id
    const [showValidation, setShowValidation] = useState({
        price: false,
    })
    const [showConfirmRemove, setShowConfirmRemove] = useState(false)
    const [showConfirmSale, setShowConfirmSale] = useState(false)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/full-listing/${id}`).then((x) => {
            setData(x)
        })
    }, [])

    const handleRemove = () => {
        api(`${process.env.REACT_APP_API_URL}/app/remove-listing/${id}`)
    }

    const handleSold = () => {
        api(`${process.env.REACT_APP_API_URL}/app/sell-listing/${id}`, {})
    }

    if (!data) return <Loading></Loading>

    return (
        <div>
            <div className='p-5 text-3xl font-semibold text-center bg-white text-neutral-800'>Edit Listing</div>
            {!data.sold_at && (
                <>
                    <FormModal open={showConfirmRemove} setOpen={setShowConfirmRemove}>
                        <Card>
                            <div className='text-lg font-medium leading-6 text-center text-gray-900'>Remove listing?</div>
                            <br />
                            <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => setShowConfirmRemove(false)}>
                                Cancel
                            </button>
                            <button
                                className={twMerge(style.button.classname, style.button._dark.classname, 'mt-2')}
                                onClick={() => {
                                    handleRemove()
                                    navigate('/marketplace')
                                }}
                            >
                                Confirm
                            </button>
                        </Card>
                    </FormModal>
                    <FormModal open={showConfirmSale} setOpen={setShowConfirmSale}>
                        <Card>
                            <div className='text-lg font-medium leading-6 text-center text-gray-900'>Mark this item as sold?</div>
                            <br />
                            <button className={twMerge(style.button.classname, style.button._primary.classname, 'mr-2')} onClick={() => setShowConfirmSale(false)}>
                                Cancel
                            </button>
                            <button
                                className={twMerge(style.button.classname, style.button._secondary.classname)}
                                onClick={() => {
                                    handleSold()
                                    navigate('/')
                                }}
                            >
                                Mark as sold
                            </button>
                        </Card>
                    </FormModal>
                    <FormWrapper buttonText={'Save'} noStyle url='app/listing' id={id} includeIdInPost {...props} callback={() => navigate('/marketplace')}>
                        {(values, setValues) => (
                            <>
                                Community: {data.organisation.name}
                                <ImageViewer className='w-64 h-64 mx-auto my-3 rounded' image={data.ownership.photo || data.ownership.product?.photo} />
                                <CurrencyField
                                    label='Price'
                                    placeholder={'0.00'}
                                    value={values.price}
                                    required
                                    inputClass={!values?.price && showValidation.price ? 'border-2 border-red-600' : ''}
                                    onChange={(val) => {
                                        setValues({ ...values, price: val })
                                        setShowValidation({ ...showValidation, price: false })
                                    }}
                                />
                                <TextAreaField label='Description' placeholder='Add any information you wish to convey to potential buyers...' value={values.notes} onChange={(val) => setValues({ ...values, notes: val })} />
                            </>
                        )}
                    </FormWrapper>
                    <div className='flex justify-between mt-5 space-x-5'>
                        <button
                            className={twMerge(style.button.classname, style.button._dark.classname)}
                            onClick={() => {
                                setShowConfirmSale(true)
                            }}
                        >
                            Sold
                        </button>
                        <button className={twMerge(style.button.classname, style.button._primary.classname)} onClick={() => setShowConfirmRemove(true)}>
                            Unlist
                        </button>
                    </div>
                </>
            )}
            {data.sold_at && (
                <div className='p-10 my-4 bg-white rounded shadow'>
                    <div className='px-4 sm:px-0'>
                        <h3 className='text-base font-semibold leading-7 text-gray-900'>Listing Information</h3>
                    </div>
                    <div className='mt-6 border-t border-gray-100'>
                        <dl className='divide-y divide-gray-100'>
                            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                <dt className='text-sm font-medium leading-6 text-gray-900'>Name</dt>
                                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.name || data.ownership?.name}</dd>
                            </div>
                            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                <dt className='text-sm font-medium leading-6 text-gray-900'>Organisation</dt>
                                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.organisation?.name}</dd>
                            </div>
                            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                <dt className='text-sm font-medium leading-6 text-gray-900'>Notes</dt>
                                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.notes}</dd>
                            </div>
                            {data.ownership.product?.size && (
                                <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                    <dt className='text-sm font-medium leading-6 text-gray-900'>Size</dt>
                                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.size}</dd>
                                </div>
                            )}
                            {data.ownership.product?.category && (
                                <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                    <dt className='text-sm font-medium leading-6 text-gray-900'>Category</dt>
                                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.category}</dd>
                                </div>
                            )}
                            {data.ownership.product?.colour && (
                                <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                    <dt className='text-sm font-medium leading-6 text-gray-900'>Colour</dt>
                                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.colour}</dd>
                                </div>
                            )}
                            {data.ownership.product?.style && (
                                <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                    <dt className='text-sm font-medium leading-6 text-gray-900'>Style</dt>
                                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.style}</dd>
                                </div>
                            )}
                            {data.ownership.product?.additionalDetails && (
                                <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                    <dt className='text-sm font-medium leading-6 text-gray-900'>Additional Details</dt>
                                    <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.ownership.product?.additionalDetails}</dd>
                                </div>
                            )}
                            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                <dt className='text-sm font-medium leading-6 text-gray-900'>sold</dt>
                                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>{data.sold_at.split('T')[0].split('-').reverse().join('/')}</dd>
                            </div>
                            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
                                <dt className='text-sm font-medium leading-6 text-gray-900'>Image</dt>
                                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                                    <ImageViewer className='h-64' image={data.ownership.photo || data.ownership.product?.photo} />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            )}
        </div>
    )
}
