import 'react-toastify/dist/ReactToastify.css'

import { useNavigate, useParams } from 'react-router'
import { TextAreaField } from '../forms/fields'
import FormWrapper from '../forms/form-wrapper'

export default function Contact(props) {
    const params = useParams()
    const navigate = useNavigate();
    return (
        <div>
            <FormWrapper url='app/contact' id='new' {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <div className="mb-3 text-3xl font-bold text-neutral-800">Contact Us</div>
                        <div className="mb-5 text-lg text-neutral-700">If you're unsure about anything just drop us a line and we'll do our best to help.</div>
                        <TextAreaField label='Message' value={values?.message} onChange={(val) => setValues({ ...values, message: val })}></TextAreaField>
                    </>
                )}
            </FormWrapper>
        </div>
    )
}
