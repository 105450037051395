import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { api } from '../services/api.service'
import moment from 'moment'

const S3FileViewer = ({ fileKey, ...rest }) => {
    const [fileUrl, setFileUrl] = useState(null)
    const [fileType, setFileType] = useState(null)
    const [error, setError] = useState(null)
    const fetchFileUrl = async (key) => {
        try {
            const cachedData = localStorage.getItem(fileKey);
            if (cachedData) {
                const { signedUrl, contentType, timestamp, contentLength } = JSON.parse(cachedData);
                if (moment().diff(moment(timestamp), 'hours') < 12) {
                    setFileUrl(signedUrl);
                    setFileType(contentType);
                    return;
                } else {
                    api(`${process.env.REACT_APP_API_URL}/s3/get-signed-url/${encodeURIComponent(key)}`)
                        .then((res) => {
                            setFileUrl(res?.signedUrl ?? null);
                            setFileType(res?.contentType ?? null);
                        })
                        .catch((err) => {
                            throw err;
                        });
                }
            } else {
                api(`${process.env.REACT_APP_API_URL}/s3/get-signed-url/${encodeURIComponent(key)}`)
                    .then((res) => {
                        setFileUrl(res?.signedUrl ?? null);
                        setFileType(res?.contentType ?? null);
                    })
                    .catch((err) => {
                        throw err;
                    });
            }
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    useEffect(() => {
        if (fileKey) {
            fetchFileUrl(fileKey)
        }
    }, [fileKey])
    
    if (!fileUrl) return <></>

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <img
            src={fileUrl ?? ''}
            {...rest}
            className={twMerge('object-cover mx-auto max-h-64', rest?.className)}
        />
    )
}

export default S3FileViewer