export default function CheckboxField({ label, value, onChange, className, inputClass, labelClass, inline, onBlur }) {
    return (
        <div className={className + (inline ? ' flex place-items-center' : '') + ' mt-2'}>
            {!inline && <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>}
            <div className={inline ? '' : 'mt-1'}>
                <input onBlur={onBlur} type='checkbox' checked={value} onChange={(e) => onChange(e.target.checked)} className={`${inline ? 'w-7 h-7' : 'w-9 h-9'} block rounded border-0 p-2 text-neutral-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 ${inputClass}`} />
            </div>
            {inline && <label className={`ml-3 block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>{label}</label>}
        </div>
    )
}
