import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import Loading from '../components/loading'

import { style } from '../style'
import { CurrencyField, SelectField, TextAreaField } from './fields'
import FormWrapper from './form-wrapper'

export default function CreateListingForm(props) {
    const [data, setData] = useState()
    const [memberships, setMemberships] = useState([])
    const [userListings, setUserListings] = useState([])
    const [existingListing, setExistingListing] = useState()
    const [values, setValues] = useState()
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id
    const [showValidation, setShowValidation] = useState({
        organisation: false,
        price: false,
    })

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/ownership/${id}`).then((x) => {
            setData(x)
        })
        api(`${process.env.REACT_APP_API_URL}/app/user-memberships`).then((x) => {
            setMemberships(x.memberships)
        })
        api(`${process.env.REACT_APP_API_URL}/app/user-listings`).then((x) => {
            setUserListings(x.listings)
        })
    }, [])

    useEffect(() => {
        if (values?.organisation) {
            const [existingListing] = userListings.filter((listing) => listing.organisation === values.organisation && listing.ownership === data._id)
            if (!!existingListing) {
                setExistingListing(existingListing)
            } else {
                setExistingListing(null)
            }
        }
    }, [values])

    const handleSubmit = (values) => {
        if (values.ownership && userListings.filter((listing) => listing.organisation === values.organisation && listing.ownership === data._id).length === 0 && values.price) {
            api(`${process.env.REACT_APP_API_URL}/app/create-listing`, values).then((x) => {
                navigate(`/edit-listing/${x.listingId}`)
            })
        } else if (!values?.organisation && !values?.price) {
            setShowValidation({ ...showValidation, organisation: true, price: true })
        } else {
            if (!values.organisation || !!existingListing) {
                setShowValidation({ ...showValidation, organisation: true })
            }
            if (!values.price) {
                setShowValidation({ ...showValidation, price: true })
            }
        }
    }

    if (!data) return <Loading></Loading>

    return (
        <div>
            <div className='p-5 text-3xl font-semibold text-center bg-white text-neutral-800'>Create Listing</div>

            {memberships.filter((x) => x.status === 'approved').length > 0 ? (
                <FormWrapper noStyle={true} url='app/ownership' hideButton id={id} {...props} callback={() => navigate(-1)}>
                    {() => (
                        <>
                            <SelectField
                                label='Community'
                                placeholder='Select a community...'
                                value={values?.organisation}
                                inputClass={(!values?.organisation && showValidation.organisation) || existingListing ? 'border-2 border-red-600' : ''}
                                options={memberships
                                    .filter((x) => x.status === 'approved')
                                    .map((x) => {
                                        return {
                                            text: x.organisation.name,
                                            value: x.organisation._id,
                                        }
                                    })}
                                onChange={(val) => {
                                    setValues({ ...values, organisation: val })
                                    setShowValidation({ ...showValidation, organisation: false })
                                }}
                            />
                            {existingListing && (
                                <div>
                                    Item already listed on this marketplace. Edit listing{' '}
                                    <Link className='underline' to={`/edit-listing/${existingListing._id}`}>
                                        here
                                    </Link>
                                    .
                                </div>
                            )}
                            <CurrencyField
                                label='Price'
                                placeholder={'0.00'}
                                value={values?.price}
                                inputClass={!values?.price && showValidation.price ? 'border-2 border-red-600' : ''}
                                onChange={(val) => {
                                    setValues({ ...values, price: val })
                                    setShowValidation({ ...showValidation, price: false })
                                }}
                            />
                            <TextAreaField label='Description' placeholder='Add any information you wish to convey to potential buyers...' value={values?.notes} onChange={(val) => setValues({ ...values, notes: val })} />
                            <button
                                className={twMerge(style.button.classname, style.button._positive.classname, 'mt-4')}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleSubmit({ ...values, ownership: id })
                                }}
                            >
                                List Now
                            </button>
                        </>
                    )}
                </FormWrapper>
            ) : (
                <div className='p-10 my-4 text-center bg-white rounded shadow-lg'>
                   <p>Thanks for your interest in listing!</p> 
                   <p>You must belong to a community to list and sell your item {':)'} </p> 
                   <p>Simply join or create a community here. <Link className="font-semibold text-blue-500" to={"/communities"}>here</Link>.</p>
                   
                </div>
            )}
        </div>
    )
}
