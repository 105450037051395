import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api, api_delete } from '../services/api.service'

import Confirm from '../components/confirm'
import Loading from '../components/loading'
import PageHeader from '../components/page-header'
import Table from '../components/table'

import { style } from '../style'

export default function AdminProducts() {
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [deleteId, setDeleteId] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/product`).then((x) => setData(x))
    }, [])

    function deleteConfirm() {
        api_delete(`${process.env.REACT_APP_API_URL}/admin/product/${deleteId}`).then((x) => {
            api(`${process.env.REACT_APP_API_URL}/admin/product`)
                .then((x) => setData(x))
                .then((x) => setDeleteId(null))
        })
    }

    if (!data) return <Loading></Loading>

    return (
        <>
            <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>

            <PageHeader
                headline={'Products'}
                actions={
                    <button className={twMerge(style.button.classname, style.button._secondary.classname)} onClick={() => navigate('/admin/manage-product/new')}>
                        Add Product
                    </button>
                }
            ></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl'>
                    <div>
                        <Table
                            exportColumns={['Name', 'Brand']}
                            exportData={data.map((x) => {
                                return {
                                    name: x.name,
                                    brand: x.brand,
                                }
                            })}
                            data={data.map((x) => {
                                return {
                                    ...x,
                                    edit: (
                                        <span className='underline cursor-pointer' onClick={() => navigate(`/admin/manage-product/${x._id}`)}>
                                            Edit
                                        </span>
                                    ),
                                    deleteCell: (
                                        <span className='underline cursor-pointer' onClick={() => setDeleteId(x._id)}>
                                            Delete
                                        </span>
                                    ),
                                }
                            })}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Brand',
                                    accessor: 'brand',
                                },
                                {
                                    Header: 'Edit',
                                    accessor: 'edit',
                                },
                                {
                                    Header: 'Delete',
                                    accessor: 'deleteCell',
                                },
                            ]}
                        ></Table>
                    </div>
                </div>
            </div>
        </>
    )
}
