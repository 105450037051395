import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'


export default function AdminCommunityRequest() {
    const [data, setData] = useState(null)
    const params = useParams();
    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/org-request/${params.id}`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader
                headline={data.name}
            ></PageHeader>

            <div className='flex'>
                <div className='w-full max-w-5xl pb-20 mx-auto my-10 text-lg 2xl:max-w-6xl text-neutral-800'>
                    <div>Name:<div className="ml-3 font-bold"> {data.name}</div></div>
                    <div>Description:<div className="ml-3 font-bold"> {data.description}</div></div>
                    <div>Is Public:<div className="ml-3 font-bold"> {data.isPublic ? 'Yes': 'No'}</div></div>
                    <div>Notes:<div className="ml-3 font-bold">{data.notes} </div></div>
                    <div>User Name:<div className="ml-3 font-bold"> {data.user?.name}</div></div>
                    <div>User Email:<div className="ml-3 font-bold"> {data.user?.email}</div></div>
                </div>
            </div>
        </>
    )
}
