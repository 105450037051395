import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'

import { BASE_URL } from '../app/home'
import { style } from '../style'
import { joinNames } from '../utils'
import { CheckboxField, TextAreaField, TextField } from './fields'
import ImageField from './fields/image-upload'
import FormWrapper from './form-wrapper'

export default function OrganisationForm(props) {
    const svgRef = useRef()
    const params = useParams()
    const navigate = useNavigate()
    let id = params.id ?? props.id

    const downloadSVG = useCallback((id) => {
        let url = svgRef.current.canvas.current.toDataURL('image/png')
        var link = document.createElement('a')
        link.download = `join${id}.png`
        link.href = url
        link.click()
    }, [])

    const copyQR = () => {
        const qr = document.getElementById('react-qrcode-logo')
        try {
            qr.toBlob(function (blob) {
                const item = new ClipboardItem({ 'image/png': blob })
                navigator.clipboard.write([item])
            })
        } catch (error) {
            alert('Unable to access clipboard.')
        }
    }

    return (
        <div>
            <PageHeader headline='Manage Organisation'></PageHeader>
            <FormWrapper url='admin/organisation' id={id} {...props} callback={() => navigate(-1)}>
                {(values, setValues) => (
                    <>
                        <div className='flex items-start justify-evenly'>
                            <ImageField label='Image' value={values?.image} onChange={(val) => setValues({ ...values, image: val })} />
                            {values._id && (
                                <div className='flex flex-col justify-center'>
                                    <h2 className='mb-4 text-sm font-semibold leading-6 text-center text-gray-700'>Share Organisation</h2>
                                    <p className='mx-auto underline cursor-pointer hover:no-underline' onClick={() => navigator.clipboard.writeText(`${BASE_URL}/join-organisation/${values?._id}`)}>
                                        <span>
                                            Copy Link
                                            <ClipboardDocumentIcon className='inline w-6 h-6 ml-2' />
                                        </span>
                                    </p>
                                    <div onClick={copyQR} className='cursor-pointer'>
                                        <QRCode value={`${BASE_URL}/join-organisation/${values?._id}`} ref={svgRef} />
                                    </div>
                                    <button type='button' className={twMerge(style.button.classname, style.button._primary.classname, 'mx-auto')} onClick={() => downloadSVG(values?._id)}>
                                        Download
                                    </button>
                                </div>
                            )}
                        </div>
                        <TextField label='Name' value={values?.name} onChange={(val) => setValues({ ...values, name: val })}></TextField>
                        <TextAreaField label='Description' value={values?.description} onChange={(val) => setValues({ ...values, description: val })}></TextAreaField>
                        <CheckboxField label='Public Community' value={values?.public} onChange={(val) => setValues({ ...values, public: val })}></CheckboxField>
                        
                        {id !== 'new' && <ViewRequests id={id} />}
                    </>
                )}
            </FormWrapper>
        </div>
    )
}

function ViewRequests({ id }) {
    const [data, setData] = useState()

    useEffect(() => {
        id !== 'new' && api(`${process.env.REACT_APP_API_URL}/admin/memberships/${id}`).then((x) => setData(x))
    }, [])

    const statuses = {
        approved: 'text-brand-700 bg-brand-50 ring-brand-600/20',
        pending: 'text-gray-600 bg-gray-50 ring-gray-500/10',
        rejected: 'text-red-800 bg-red-50 ring-red-600/20',
        isOrgAdmin: 'text-blue-800 bg-blue-50 ring-blue-600/20',
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const handleApprove = (type, status, userID) => {
        if (type === 'status') {
            api(`${process.env.REACT_APP_API_URL}/admin/membership/${userID}`, { type: 'status', status: status }).then((x) => {
                api(`${process.env.REACT_APP_API_URL}/admin/memberships/${id}`).then((x) => setData(x))
            })
        } else {
            api(`${process.env.REACT_APP_API_URL}/admin/membership/${userID}`, { type: 'admin' }).then((x) => {
                api(`${process.env.REACT_APP_API_URL}/admin/memberships/${id}`).then((x) => setData(x))
            })
        }
    }
    if (!data) return <Loading></Loading>

    return (
        <div className='my-6'>
            <h2 className='text-lg font-semibold text-gray-600'>View Memberships:</h2>
            <ul role='list' className='divide-y divide-gray-100'>
                {data?.map((req) => (
                    <li key={req._id} className='flex items-center justify-between px-10 py-5 gap-x-6'>
                        <div className='min-w-0'>
                            <div className='flex items-start gap-x-3'>
                                <p className='text-sm font-semibold leading-6 text-gray-900'>{joinNames(req.user.name, req.user.lastName)}</p>
                                <p className={classNames(statuses[!req.isOrgAdmin ? req.status : 'isOrgAdmin'], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset')}>{!req.isOrgAdmin ? req.status : 'Admin'}</p>
                            </div>
                            <div className='flex items-center mt-1 text-xs leading-5 text-gray-500 gap-x-2'>
                                <p className='whitespace-nowrap'>{req.user.email}</p>
                            </div>
                        </div>
                        {req.status === 'pending' && (
                            <div className='flex items-center flex-none gap-x-4'>
                                <button type='button' onClick={() => handleApprove('status', 'approved', req._id)} className={twMerge(style.button.classname, style.button._positive.classname, 'sm:block')}>
                                    Approve<span className='sr-only'>, {joinNames(req.user.name, req.user.lastName)}</span>
                                </button>
                                <button type='button' onClick={() => handleApprove('status', 'rejected', req._id)} className={twMerge(style.button.classname, style.button._warning.classname)}>
                                    Reject<span className='sr-only'>, {joinNames(req.user.name, req.user.lastName)}</span>
                                </button>
                            </div>
                        )}
                        {req.status === 'approved' && (
                            <div className='flex items-center flex-none gap-x-4'>
                                {!req.isOrgAdmin && (
                                    <button type='button' onClick={() => handleApprove('admin', '', req._id)} className={twMerge(style.button.classname, style.button._primary.classname, 'sm:block')}>
                                        Make Admin<span className='sr-only'>, {joinNames(req.user.name, req.user.lastName)}</span>
                                    </button>
                                )}
                                {req.isOrgAdmin && (
                                    <button type='button' onClick={() => handleApprove('admin', '', req._id)} className={twMerge(style.button.classname, style.button._warning.classname)}>
                                        Remove Admin<span className='sr-only'>, {joinNames(req.user.name, req.user.lastName)}</span>
                                    </button>
                                )}
                                {!req.isOrgAdmin && (
                                    <button type='button' onClick={() => handleApprove('status', 'rejected', req._id)} className={twMerge(style.button.classname, style.button._warning.classname)}>
                                        Remove<span className='sr-only'>, {joinNames(req.user.name, req.user.lastName)}</span>
                                    </button>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}
