import { twMerge } from 'tailwind-merge'

import { BASE_URL } from '../app/home'

export default function HousekeepingLinks({ className }) {
    return (
        <div className={twMerge('grid grid-cols-2 sm:flex items-end justify-around px-8 py-2 text-center text-sm border-t-2 border-neutral-500  text-neutral-700', className)}>
            <a target='_blank' href={`${BASE_URL}/privacy-policy.html`}>
                Privacy Policy
            </a>
            <a target='_blank' href={`${BASE_URL}/terms-of-service.html`}>
                Terms of Service
            </a>
            <a target='_blank' href={`${BASE_URL}/disclaimer.html`}>
                Disclaimer
            </a>
            <a target='_blank' href={`${BASE_URL}/acceptable-use-policy.html`}>
                Acceptable Use Policy
            </a>
        </div>
    )
}
