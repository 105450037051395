export default function CurrencyField({ label, value, onChange, className, inputClass, labelClass, onBlur, required }) {
    return (
        <div className={className + ' mt-2'}>
            <label className={`block text-sm font-medium leading-6 text-neutral-600 ${labelClass}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </label>
            <div className={`relative inline-block w-full mt-1 overflow-hidden rounded shadow-sm ${inputClass}`}>
                <div className='absolute inset-y-0 left-0 flex items-center p-3 border-r border-gray-200 pointer-events-none bg-slate-200'>
                    <span className='text-gray-500 sm:text-sm'>£</span>
                </div>
                <input required={required} onBlur={onBlur} type='number' value={value} onChange={(e) => onChange(e.target.value)} className={` bg-slate-100 w-full lock rounded border-0 py-2 pl-12 pr-3 text-neutral-700 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 outline-none focus:ring-inset focus:ring-1 focus:ring-gray-200 sm:text-sm sm:leading-6 `} placeholder='0.00' />
            </div>
        </div>
    )
}
