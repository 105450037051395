import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

export default function Card({ className = '', header = false, expandable = false, children }) {
    const [isOpen, setOpen] = useState(!expandable)

    return (
        <section className={`bg-white rounded-md border-slate-500  overflow-hidden select-none shadow-lg ${className ?? ''}`}>
            {header && (
                <header onClick={expandable ? () => setOpen(!isOpen) : null} className={`flex justify-between px-6 items-center h-11 text-lg tracking-wide border-brand-600 ${expandable ? 'cursor-pointer' : ''} ${isOpen ? '' : ''}`}>
                    {typeof header === 'string' ? <h1>{header}</h1> : header}
                    {expandable && (isOpen ? <ChevronUpIcon className='w-6 cursor-pointer text-neutral-800' /> : <ChevronDownIcon className='w-6 cursor-pointer text-neutral-800' />)}
                </header>
            )}
            {isOpen ? children : <></>}
        </section>
    )
}
