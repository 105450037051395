import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { api } from '../services/api.service'

import { style } from '../style'
import { TextAreaField } from './fields'

export default function LostProductForm(props) {
    const [values, setValues] = useState({})

    return (
        <div>
            <TextAreaField labelClass='text-black' label='Return details' value={values.returnInstructions} onChange={(val) => setValues({ ...values, returnInstructions: val })}></TextAreaField>
            <br />
            <button
                className={twMerge(style.button.classname, style.button._secondary.classname)}
                onClick={() => {
                    //toast('Item logged as lost!')
                    api(`${process.env.REACT_APP_API_URL}/app/mark-as-lost/${props.id}`, values).then((x) => props.submitted())
                }}
                type='button'
            >
                Submit
            </button>
        </div>
    )
}
