import React, { useEffect, useState } from 'react'

import { api } from '../services/api.service'

import Loading from '../components/loading'
import PageHeader from '../components/page-header'

export default function AnalyticsDashboard() {
    const [data, setData] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/admin/analytics`).then((x) => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (
        <>
            <PageHeader headline='View Analysis'></PageHeader>
            <div className='max-w-6xl p-8 mx-auto my-4 bg-white rounded shadow'>
                <div className='mb-10'>
                    <h2 className='text-2xl font-semibold'>View Product Statistics</h2>
                    <ProductStats data={data} />
                    <ProductInfoTable data={data} />
                </div>
                <div>
                    <h2 className='text-2xl font-semibold'>View Organisation Statistics</h2>
                    <OrganisationStats data={data} />
                    <OrganisationInfoTable data={data} />
                </div>
            </div>
        </>
    )
}

const StatBlock = ({ title, data, percent }) => {
    return (
        <div className='flex flex-wrap items-baseline justify-between px-4 py-10 bg-white gap-x-4 gap-y-2 sm:px-6 xl:px-8'>
            <dt className='text-sm font-medium leading-6 text-gray-500'>{title}</dt>
            <dd className='flex-none w-full text-3xl font-medium leading-10 tracking-tight text-gray-900'>
                {data}
                {percent ? '%' : ''}
            </dd>
        </div>
    )
}

function ProductStats(props) {
    const { productStatArray } = props.data
    return (
        <dl className='grid grid-cols-1 gap-px mx-auto bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3'>
            <StatBlock title='Products currently lost' data={productStatArray?.currentLostProducts} />
            <StatBlock title='Products found' data={productStatArray?.currentFoundProducts} />
            <StatBlock title='Products returned' data={productStatArray?.currentReturnedProducts} />
        </dl>
    )
}

function ProductInfoTable(props) {
    const { ProductBreakdownAnalysis } = props.data
    return (
        <div className='px-4 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-center'>
                <div className='sm:flex-auto'>
                    <h1 className='text-base font-semibold leading-6 text-gray-900'>Product Breakdown</h1>
                </div>
            </div>
            <div className='mt-8 -mx-4 sm:-mx-0'>
            {ProductBreakdownAnalysis?.length === 0 && <p>You have no product data</p>}
            {ProductBreakdownAnalysis?.length > 0 && 
                <table className='min-w-full divide-y divide-gray-300'>
                    <thead>
                        <tr>
                            <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                                Item Name
                            </th>
                            <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                                Total Ownerships
                            </th>
                            <th scope='col' className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell'>
                                Currently Lost
                            </th>
                            <th scope='col' className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell'>
                                Found Events
                            </th>
                            <th scope='col' className='hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell'>
                                Total Returned
                            </th>
                        </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                        {ProductBreakdownAnalysis && ProductBreakdownAnalysis.map((item) => (
                            <tr key={item.name}>
                                <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0'>{item.product}</td>
                                <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>{item.totalOwnerships}</td>
                                <td className='hidden px-3 py-4 text-sm text-gray-500 whitespace-nowrap sm:table-cell'>{item.numberLost}</td>
                                <td className='hidden px-3 py-4 text-sm text-gray-500 whitespace-nowrap lg:table-cell'>{item.numberFound}</td>
                                <td className='hidden px-3 py-4 text-sm text-gray-500 whitespace-nowrap lg:table-cell'>{item.totalReturns}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
            </div>
        </div>
    )
}

function OrganisationStats(props) {
    const { listingStatArray } = props.data
    return (
        <dl className='grid grid-cols-1 gap-px mx-auto bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3'>
            <StatBlock title='Total organisations' data={listingStatArray?.totalOrganisations} />
            <StatBlock title='Number of items listed' data={listingStatArray?.totalItemsListed} />
            <StatBlock title='Number of items sold' data={listingStatArray?.totalItemsSold} />
        </dl>
    )
}

function OrganisationInfoTable(props) {
    const { organisationBreakdownAnalysis } = props.data
    return (
        <div className='px-4 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-center'>
                <div className='sm:flex-auto'>
                    <h1 className='text-base font-semibold leading-6 text-gray-900'>Organisation Breakdown</h1>
                </div>
            </div>
            <div className='mt-8 -mx-4 sm:-mx-0'>
                {organisationBreakdownAnalysis?.length === 0 && <p>You have no organisations</p>}
                {organisationBreakdownAnalysis?.length > 0 && 
                    <table className='min-w-full divide-y divide-gray-300'>
                        <thead>
                            <tr>
                                <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                                    Organisation Name
                                </th>
                                <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                                    Items for Sale
                                </th>
                                <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
                                    Items Sold
                                </th>
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                            {organisationBreakdownAnalysis.map((org) => (
                                <tr key={org.organisation}>
                                    <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0'>{org.organisation}</td>
                                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>{org.itemsListed}</td>
                                    <td className='px-3 py-4 text-sm text-gray-500 whitespace-nowrap'>{org.itemsSold}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
                
            </div>
        </div>
    )
}