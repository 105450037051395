const PRIMARY_BRAND_COLOUR = ''
const PRIMARY_ACCENT_COLOUR = 'white'
const PRIMARY_EMPHASIS_COLOUR = 'gray-600'

const SECONDARY_BRAND_COLOUR = 'brand-500'
const SECONDARY_ACCENT_COLOUR = 'white'
const SECONDARY_EMPHASIS_COLOUR = 'brand-700'

const POSITIVE_BRAND_COLOUR = 'brand-500'
const POSITIVE_ACCENT_COLOUR = 'white'
const POSITIVE_EMPHASIS_COLOUR = 'brand-500'

const WARNING_BRAND_COLOUR = 'yellow-700'
const WARNING_ACCENT_COLOUR = 'yellow-100'
const WARNING_EMPHASIS_COLOUR = 'red-200'

export const style = {
    button: {
        classname: 'font-medium px-3 py-2 cursor-pointer rounded w-full',
        _primary: {
            classname: `bg-[#363835] hover:bg-${PRIMARY_EMPHASIS_COLOUR} text-${PRIMARY_ACCENT_COLOUR}`,
        },
        _secondary: {
            classname: `text-${SECONDARY_ACCENT_COLOUR} bg-${SECONDARY_BRAND_COLOUR} hover:bg-${SECONDARY_EMPHASIS_COLOUR}`,
        },
        _positive: {
            classname: `text-${POSITIVE_ACCENT_COLOUR} bg-${POSITIVE_BRAND_COLOUR} hover:bg-${POSITIVE_EMPHASIS_COLOUR}`,
        },
        _warning: {
            classname: `text-${WARNING_BRAND_COLOUR} bg-${WARNING_ACCENT_COLOUR} border border-${WARNING_BRAND_COLOUR} hover:bg-${WARNING_EMPHASIS_COLOUR} underline`,
        },
        _dark: {
            classname: `text-white bg-black border border-black`,
        },
    },
}
